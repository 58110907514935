import React from 'react';

import './style.css';

export default function Footer({children}) {
    return (
        <footer>
            {children}
            <a rel="noopener noreferrer"target="_blank" href="https://br.freepik.com/vetores/fundo">Fundo vetor criado por freepik - br.freepik.com</a>
        </footer>
    )
}