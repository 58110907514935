import React from 'react';
import {FiLinkedin,FiMail,FiSmartphone,FiGithub} from 'react-icons/fi'
import {FaWhatsapp} from 'react-icons/fa'
import firebase from '../../firebase/config'
import './style.css';

export default function Contact({link,iconName, iconColor, iconSize,contactText}) {
    const contactIcon = (icon) => {
        switch (icon) {
            case 'linkedin':
                return <FiLinkedin color = {iconColor} size = {iconSize}/>;
            case 'email':
                return <FiMail color = {iconColor} size = {iconSize}/>;
            case 'phone':
                return <FiSmartphone color = {iconColor} size = {iconSize}/>;
            case 'github':
                return <FiGithub color = {iconColor} size = {iconSize}/>;
            case 'whatsapp':
                return <FaWhatsapp color = {iconColor} size = {iconSize}/>;
            default:
                return <FiMail color = {iconColor} size = {iconSize}/>;
        }
    }

    function fireAnalytics() {
        let analytics = firebase.analytics()
        analytics.logEvent('select_item',{type: 'contacts',text:iconName })
    }

    return (
        link ?

        <a onClick={fireAnalytics} href = {link} target = '_blank' rel="noopener noreferrer" className = 'contact-container'>
            {contactIcon(iconName)} <span>{contactText}</span>
        </a>

        :

        <span className = 'contact-container'>
            {contactIcon(iconName)} <span>{contactText}</span>
        </span>

    )
}