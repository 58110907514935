import React from 'react';

import './style.css'

export default function GridCollection({children, backcolor,title,id}) {
    return (
        <section className="grid-colllection-container" style = {{background: backcolor}}>
            <h2 className='section-title'>{title}</h2>
            <div id={id} className="grid-collection">
                {children}
            </div>
        </section>
    )
}