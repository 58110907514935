import React from 'react';
import Home from './pages/Home';

import './global.css'


function App() {
  
  return (
    <div>
      <Home />
    </div>
  );
}

export default App;
