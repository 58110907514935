import React from 'react';

import './style.css';

export default function Header({children, name, languageId, flagClick}) {
    const Flag = ()=> {
        return (
            languageId ?
            <img onClick = {flagClick} className = 'flag' src={`https://www.countryflags.io/br/shiny/32.png`} alt = 'flag'></img>
            :
            <img  onClick = {flagClick} className = 'flag' src={`https://www.countryflags.io/us/shiny/32.png`} alt = 'flag'></img>
        )
    }
    return(
        <header className='header-container'>
            <Flag />
            <div className="header-name">{name}</div>
            <div className="header-contacts">{children}</div>
        </header>
    )
}