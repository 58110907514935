 import * as firebase from 'firebase/app';
 import 'firebase/firestore';
 import 'firebase/auth';
 import 'firebase/analytics';
 import 'firebase/storage';
 
 // Your web app's Firebase configuration
 const firebaseConfig = {
    apiKey: "AIzaSyBiL46XqWsuJF3qPIiBzfm3oQ_H0M6f8sY",
    authDomain: "ricardosantos-f00c1.firebaseapp.com",
    databaseURL: "https://ricardosantos-f00c1.firebaseio.com",
    projectId: "ricardosantos-f00c1",
    storageBucket: "ricardosantos-f00c1.appspot.com",
    messagingSenderId: "242683621965",
    appId: "1:242683621965:web:855c6bff22b04928",
    measurementId: "G-V1Y5F5TBX2"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase;
