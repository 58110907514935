import React,{useState} from 'react';
import firebase from '../../firebase/config'
import certificateModel from '../../assets/certificateModel.png'

import './style.css'

let stoRef = firebase.storage().ref()

export default function CourseItem({link,certificateImage,text}) {

    const [image,setImage] = useState(certificateModel)

    function fireAnalitics () {
        let analytics = firebase.analytics()
        analytics.logEvent('select_item',{type:'courses',text: text})
    }


    stoRef.child(certificateImage).getDownloadURL().then(url=>{
        setImage(url)
    })

    return (
        <a onClick={fireAnalitics} href = {link} target = '_blank' rel="noopener noreferrer" className="course-item-container">
            <img src={image} alt="Certificate"/>
            <p className="course-item-description">
                {text}
            </p>
        </a>
    )
}