import React from 'react';
import firebase from '../../firebase/config'

import './style.css';



export default function ProfileItem({type,image,linksBrands,text}) {
    const fireAnalytics = (link) => {
        let analytics = firebase.analytics()
        analytics.logEvent('select_item', {type:'portifolio',text, link})
    }
    return (
        <div className = {type}>
            <img className = 'gif' src={image} alt="recipes"/>
            <div className='content'>
                <p className='description'>
                    {text}
                </p>
                <div className = 'link-container'>
                    {linksBrands.map((link,index)=> <a onClick={()=>fireAnalytics(link.link)} key = {index} href={link.link} target = '_blank' rel="noopener noreferrer" >{link.brand}</a>
                    )}
                </div>
            </div>
        </div>
    )
}