import React, {useState, useEffect} from 'react';
import Header from '../../components/Header'
import Contacts from '../../components/Contacts'
import data from '../../Utils'
import {FaReact, FaNode,FaJsSquare, FaGithub,FaYoutube} from 'react-icons/fa'
import CourseItem from '../../components/CourseItem'
import PortifolioItem from '../../components/ProfileItem'
import GridCollection from '../../components/GridCollection'
import firebase from '../../firebase/config'
import Footer from '../../components/Footer'
import '../../firebase/config'
import './style.css'

import profilePhoto from '../../assets/profilePhoto.jpg'

import recipes from '../../assets/recipes.gif'
import movesearch from '../../assets/movesearch.gif'
import snakegame from '../../assets/snakegame.gif'
import todolist from '../../assets/todolist.gif'

let db = firebase.firestore()


export default function Home() {
    const [languageId, setLanguageId] = useState(/^pt.*$/.test(navigator.language) ? 0 : 1)
    const [myCourses, setMyCourses]  = useState([])
    const [myContacts] = data
    
    const jobName = ['Desenvolvedor FullStack Javascript', 'Javascript Fullstack Developer']
    const profileDesctiption = [
        'Olá, sou desenvolvedor fullstack, apaixonado por Javascript e trabalho com as tecnologias React, React Native e Node.js. Em 2019 me aventurei a participar de uma Marotona da IBM chamada Marathon Behind the Code em que consistia em 8 desafios on-line sobre IA e fiquei entre os TOP 100 conquistando o direito de participar do nono desafio presencial. Machine Learn é a minha próxima viagem.',
        "Hi, I'm a fullstack developer, passionate about Javascript and I work with React, React Native and Node.js. In 2019 I ventured to participate in an IBM Marathon called Marathon Behind the Code which consisted of 8 online AI challenges and I was among the TOP 100 winning the right to participate in the ninth face-to-face challenge. Machine Learn is my next trip."
    ]

    const profileTitle = ['Perfil','Profile']
    const coursesTitle = ['Cursos','Courses']
    const portifolioTitle = ['Portifólio','Portifolio']

    useEffect(()=>{
        let myCs = []
        
        db.collection('courses').get().then((querySnapshot) =>{
            querySnapshot.forEach(doc=>{
                myCs.push(doc.data())
            })
            setMyCourses(myCs)
        }) 
        
        
    },[])
    
    const portifoliosDescription = [
        [
            'Aplicativo de tarefas feito com puro javascript, com as funcionalidades de adicionar, alterar, listar e deletar (CRUD). Requisitos exigidos para um desafio onde fiquei com 100/100.',
            'Task application made with pure javascript, with the functionality of creating, retrieving, updating and deleting (CRUD). Statements required for a challenge where I got 100/100.'
        ],
        [
            'Jogo da Cobrinha feito com vanila javascript para atender requisitos de um desafio, utilizando assincronismo para fazer com que o mesmo funcione.',
            'Snake game made with vanila javascript to meet the requirements of a challenge, using asynchronism to make it work.'
        ],
        [
            'Aplicativo para consulta de receitas culinárias, feito com React Native, Redux e outras tecnologias, atendendo requisitos minimos proposto pelo curso CS50m para realização de um projeto feito do zero.',
            'Application for consulting culinary recipes, made with React Native, Redux and other technologies, meeting minimum requirements proposed by the CS50m course to carry out a project made from scratch.'
        ],
        [
            'Aplicativo feito com React Native, projeto para o curso CS50m onde o desafio era consumir uma API. Foi utilizado axios para realizar as requisições HTTP.',
            'Application made with React Native, project for the CS50m course where the challenge was to consume an API. axios were used to perform HTTP requests.'
        ],
    ]

    const handleFlagClick = ()=> {
        setLanguageId(languageId ? 0 : 1)
    }

    
    
    

    return (
        <div className = 'home-container'>
        
            <Header flagClick = {handleFlagClick} languageId = {languageId} name = 'Ricardo Santos'>
                <Contacts contacts = {myContacts}/>
            </Header>
            <h1 className = 'job-name'>
                {jobName[languageId]}
            </h1>

            <section className='profile-container'>
                
                <img src={profilePhoto} alt="Ricardo Santos - developer" className="profile-photo"/>
                <div className="profile-description-container">
                    <h2 className="section-title">{profileTitle[languageId]}</h2>
                    <div className="profile-description">
                        {profileDesctiption[languageId]}
                    </div>
                    
                </div>
                <div className='description-brands'>
                    <FaJsSquare size={48}/>
                    <FaNode size={48}/>
                    <FaReact size={48}/>
                </div>
            </section>
            <GridCollection backcolor = 'white' title = {coursesTitle[languageId]} >
                {myCourses.map((courseItem,index) => (
                    <CourseItem 
                        Key = {index}
                        certificateImage = {courseItem.imageRef}
                        link = {courseItem.linkCertificate}
                        text = {courseItem.description[languageId]}
                    />            
                )) }       
                
            </GridCollection>

            <GridCollection id='profile-collection' backcolor = '#DEE9FC' title = {portifolioTitle[languageId]} >
                <PortifolioItem 
                    linksBrands = {[
                        {link: 'https://www.youtube.com/watch?v=G1A5MaxBs08', brand:<FaYoutube size = {25} color = 'white' />}, 
                        {link: 'https://github.com/rick-ssa/task-challenge', brand:<FaGithub size = {25} color = 'white'/>}
                    ]} 
                    text = {portifoliosDescription[0][languageId]}
                    image={todolist} 
                    type = 'portifolio-item-web'
                />

                <PortifolioItem 
                    linksBrands = {[
                        {link: 'https://www.youtube.com/watch?v=HOTz4ve3OWk', brand:<FaYoutube size = {25} color = 'white' />}, 
                        {link: 'https://github.com/rick-ssa/snake-game', brand:<FaGithub size = {25} color = 'white'/>}
                    ]} 
                    
                    text = {portifoliosDescription[1][languageId]}
                    image={snakegame} 
                    type = 'portifolio-item-web'
                />

                <PortifolioItem 
                    linksBrands = {[
                        {link: 'https://www.youtube.com/watch?v=RyZ2591qQoQ&t=63s', brand:<FaYoutube size = {25} color = 'white' />}, 
                        {link: 'https://github.com/rick-ssa/cs50m-final-project', brand:<FaGithub size = {25} color = 'white'/>}
                    ]} 
                    
                    text = {portifoliosDescription[2][languageId]}
                    image= {recipes} 
                    type = 'portifolio-item-mobile'
                />

                <PortifolioItem 
                    linksBrands = {[
                        {link: 'https://www.youtube.com/watch?v=jRuIWaj7E-g&t', brand:<FaYoutube size = {25} color = 'white' />}, 
                        {link: 'https://github.com/rick-ssa/cs50m-project2', brand:<FaGithub size = {25} color = 'white'/>}
                    ]} 
                    
                    text = {portifoliosDescription[3][languageId]}
                    image={movesearch} 
                    type = 'portifolio-item-mobile'
                />
            </GridCollection>
            <Footer>
                <Contacts id='footer' contacts = {myContacts}/>
            </Footer>

        </div>
    )
}