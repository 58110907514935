import React from 'react';
import Contact from  '../Contact'

import './style.css'

export default function Contacts({contacts,id}) {
    return (
        <div id = {id} className = 'contacts-container'>
        {    contacts.map((contact,index)=>(
                <Contact 
                    key = {index}
                    link = {contact.link} 
                    iconColor = {contact.iconColor} 
                    iconSize = {contact.iconSize} 
                    iconName = {contact.iconName}
                    contactText= {contact.text}
                />
            ))}
        </div>
    )
}

