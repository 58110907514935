const myContacts = [
    {
      link: 'https://github.com/rick-ssa',
      iconColor: 'white',
      iconSize: 20,
      iconName: 'github',
      text: 'rick-ssa'
    },
    {
      link: 'https://www.linkedin.com/in/rick-ssa/',
      iconColor: 'white',
      iconSize: 20,
      iconName: 'linkedin',
      text: 'rick-ssa'
    },
    {
      link: 'mailto:dev@ricardosantos.me',
      iconColor: 'white',
      iconSize: 20,
      iconName: 'email',
      text: 'dev@ricardosantos.me'
    },
    {      
      link: 'tel:+5575999839238',
      iconColor: 'white',
      iconSize: 20,
      iconName: 'phone',
      text: '+55 75 999839238'
    },
    {      
      link: 'https://api.whatsapp.com/send?phone=5575999839238',
      iconColor: 'white',
      iconSize: 20,
      iconName: 'whatsapp',
      text: '+55 75 999839238'
    }
  ]

  const myCourses = [
    {
      linkCertificate: 'https://credentials.edx.org/credentials/97e4fce4e73d424da93a9c2568fe7886/',
      description:['Certificado de conclusão do programa CS50 de Harvard que inclui os cursos de Introdução a Ciềncia da Computação e Aplicativos Mobile.',
        'Certificate of completion of the Harvard CS50 program, which includes Introduction to Computer Science and Mobile Applications courses.'
      ],
    },
    {
      linkCertificate: 'https://courses.edx.org/certificates/89ae42b0085f40569a864a16f24df0c7',
      description:['Certificado de conclusão do curso CS50m de Harvard de aplicativos mobile utilizando React Native, Redux, React Navigation e outras tecnologias.',
        'Harvard CS50m course certificate for mobile applications using React Native, Redux, React Navigation and other technologies.'
      ],
    },
    {
      linkCertificate: 'https://courses.edx.org/certificates/baa4608a955a45b3a5c0e28acb510ac3',
      description:['Certificado de conclusão do curso CS50 de Harvard sobre Introdução a Ciência da Computação, utilizando javascript, python, css, html, http e outras tecnologias.',
        'Certificate of completion of the Harvard CS50 course on Introduction to Computer Science, using javascript, python, css, html, http and other technologies.'
      ]
    },
    {
      linkCertificate: 'https://courses.edx.org/certificates/63260f2145e0429384917d178e33be06',
      description:['Certificado de conclusão do curso Fundamentos Java para Desenvolvimento Android.',
        'Certificate of completion of the course Java Fundamentals for Android Development.'
      ]
    },
    {
      linkCertificate: 'https://www.dropbox.com/s/ur1ueq9pb3vua6b/9d9647fe-52e5-4280-a22c-09965b3bff64.pdf?dl=0',
      description:['Certificado de participação da Maratona Behind the Code da IBM, participando de 8 desafios online e um presencial unicamente para os Top 100.',
        'Certificate of participation in the IBM Behind the Code Marathon, participating in 8 online challenges and one in-person only for the Top 100.'
      ]
    },
  ]


  export default [myContacts,myCourses];
